import React from 'react';
import { Table, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';


const DataTable = ({items, loading}) => {
  const itemsDisplay = items.map(item => {
    return (
      <tr style={{/*backgroundColor: 'red'*/}} key={item.id}>
        <td>{item.id}</td>
        <td className='quebra-linha'>{item.nome}</td>
        <td>
          <div style={{width:"110px"}}>
            <Link to={`/backlog/${item.id}`} target="_blank" rel="noopener noreferrer">
              <Button color="warning">Acessar</Button>
            </Link>
          </div>
        </td>
      </tr>
      )
    })

  return (
    <>
      {loading ? (
        <div style={{margin: 'auto', width: '10%'}}>
          <ReactLoading type={'spin'} color={'gray'} height={40} width={40} />
        </div>
      ): (
        <Table responsive>
          <thead>
            <tr>
              <th style={{width: "20%"}}>ID</th>
              <th style={{width: "60%"}}>Cliente - Projeto</th>
              <th style={{width: "20%"}}>Backlog</th>
            </tr>
          </thead>
          <tbody>
            {itemsDisplay}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default DataTable