import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DataTable from '../../Components/Backlog/Tables/DataTable';
import {useParams} from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';




const Backlog = () =>  {
  const {id} = useParams();

  const [items, setItems] = useState([]);
  const [cliente, setCliente] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const getItems = () => {
    try {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API}/backlog?id=${id}`)
      .then(response => response.json())
      .then(items => {
        if(items.length > 0)
          setCliente(items[0].cliente);
        setItems(items)
      })
      .catch(err => console.log(err))
      .finally(()=>{
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
    }
  }
  
  const homologado = (item, texto) => {
    try {
      setLoading(true);
      console.log('item > ', item);
      fetch(`${process.env.REACT_APP_API}/backlog/${item.id}`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          texto,
          status: 'Homologado (QA)',
          cliente,
          task: item.nome
        })
      })
      .then(response => response.json())
      .then(item => {
        setRefresh(!refresh);
        console.log('item >>> ', item);
        if(item.success)
          NotificationManager.success('Homologação enviada com sucesso!', 'Tudo certo!');
        else
          NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
      })
      .catch(err => {
        NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
        setRefresh(!refresh);
      }).finally(()=>{
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');    
    }
  }

  const naoHomologado = (item, texto) => {
    try {
      setLoading(true);
      console.log('item > ', item);
      fetch(`${process.env.REACT_APP_API}/backlog/${item.id}`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          texto,
          status: 'Não Homologado (QA)',
          cliente,
          task: item.nome
        })
      })
        .then(response => response.json())
        .then(item => {
          console.log('item >>> ', item);
          setRefresh(!refresh);
          if(item.success)
            NotificationManager.success('Homologação enviada com sucesso!', 'Tudo certo!');
          else
            NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
        })
        .catch(err => {
          NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
          setRefresh(!refresh);
        }).finally(()=>{
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
    }
  }

  useEffect(()=>{
    getItems();
  },[refresh]);

    return (
      <Container className="App">
        <NotificationContainer/>
        <Row>
          <Col>
            <h1 style={{margin: "20px 0"}}>Homologação</h1>
            <h3>{cliente}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable items={items} homologado={homologado} naoHomologado={naoHomologado} loading={loading} />
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <ModalForm buttonLabel="Add Item" addItemToState={this.addItemToState}/>
          </Col>
        </Row> */}
      </Container>
    )
}

export default Backlog;