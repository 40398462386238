import React from 'react';
import { Table } from 'reactstrap';
import ModalForm from '../Modals/Modal';
import { parseISO, format, isValid } from 'date-fns';
import ReactLoading from 'react-loading';

const DataTable = ({items, loading, naoHomologado, homologado}) => {
  const itemsDisplay = items.map(item => {
    let bgColor = ""
    let showActions = true;
    if(item.status === "Homologado (QA)"){
      bgColor = "#d6ffd6";
      showActions = false;
    }else if(item.status === "Não Homologado (QA)"){
      bgColor = "#ffdede";
    }
    return (
      <tr style={{backgroundColor: bgColor}} key={item.id}>
        <td className='quebra-linha'>{item.nome}</td>
        <td>{isValid(new Date(item.data_qa)) ? format(parseISO(item.data_qa), 'dd/MM/yyy'): '-'}</td>
        <td className='quebra-linha'>{item.status}</td>
        <td>
          {showActions ? (
            <div style={{width:"110px"}}>
              <ModalForm buttonLabel="Não" item={item} naoHomologado={naoHomologado}/>
              {' '}
              {/* <Button color="success" onClick={() => this.confirmHomolog(item.id)}>Sim</Button> */}
              <ModalForm buttonLabel="Sim" item={item} homologado={homologado}/>
            </div>
          ): (
            <div>-</div>
          )}
        </td>
      </tr>
      )
    })

  return (
    <>
      {loading ? (
        <div style={{margin: 'auto', width: '10%'}}>
          <ReactLoading type={'spin'} color={'gray'} height={40} width={40} />
        </div>
      ): (
        <Table responsive>
          <thead>
            <tr>
              <th style={{width: "50%"}}>Descrição</th>
              <th style={{width: "15%"}}>Em QA desde</th>
              <th style={{width: "15%"}}>Status</th>
              <th style={{width: "20%"}}>Homologado?</th>
            </tr>
          </thead>
          <tbody>
            {itemsDisplay.length > 0 ? itemsDisplay : (
              <>
              <div style={{textAlign: 'right'}}>
                Nenhum item disponível para homologação!
              </div>
              </>
            )}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default DataTable