import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DataTable from '../../Components/Projetos/Tables/DataTable';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Projetos = () =>  {
  const cd = new URLSearchParams(window.location.search).get("cd")

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getItems = () => {
    try {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API}/projeto`)
        .then(response => response.json())
        .then(items => {
          setItems(items)
        })
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      NotificationManager.error('Estamos passando por uma instabilidade! Tente novamente em alguns segundos', 'Ops...');
    }
    
  }

  useEffect(()=>{
    if(cd && cd === "clickativo")
      getItems();
    else
      alert('Sem permissão!');
  },[]);

  
  return (
    <Container className="App">
      <NotificationContainer/>
      <Row>
        <Col>
          <h1 style={{margin: "20px 0"}}>Projetos</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable items={items} loading={loading} />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <ModalForm buttonLabel="Add Item" addItemToState={this.addItemToState}/>
        </Col>
      </Row> */}
    </Container>
  );
}

export default Projetos;