import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

class AddEditForm extends React.Component {
  state = {
    id: 0,
    texto: '',
  
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  submit = () => {
    console.log('this.props.item >> ', this.props.item)
    console.log('this.state.texto >> ', this.state.texto)
    if(this.props.homologado){
      console.log('homologado !!!!');
      this.props.homologado(this.props.item, this.state.texto);
    }else{
      console.log('não ####');
      this.props.naoHomologado(this.props.item, this.state.texto);
    }
  }
  componentDidMount(){
  }

  render() {
    return (
      <Form>
        <FormGroup>
        <Label for="hobby">{this.props.text}</Label>
          <Input type="textarea" name="texto" id="texto" rows="6" onChange={this.onChange} value={this.state.texto}  />
        </FormGroup>
        <Button onClick={this.submit} style={{float: 'right'}}>Enviar</Button>
      </Form>
    );
  }
}

export default AddEditForm